//
// Form editors.scss
//

// ck-editor

.ck.ck-toolbar {
  background-color: $gray-200 !important;
  border: 1px solid $gray-300 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  border-color: $gray-300 !important;
  box-shadow: none !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
  background: $dropdown-bg !important;
  border: 1px solid $dropdown-border-color !important;
}

.ck-editor__editable {
  min-height: 245px !important;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: $card-bg !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: $gray-600 !important
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: $gray-200 !important;
}


.ck.ck-editor__main, .ck-viewer  {
  border: 1px solid #ddd;
  
}
.ck.ck-editor__main, .ck-viewer  {
  p, h1,h2,h3,h4,h5,h6{
    margin-bottom: 0;
  }
}




/* CKEditor specific styles */
.cke_reset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}