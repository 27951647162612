//
// Select 2
//

@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select .ng-select-container {
  border-radius: $border-radius !important;
  min-height: 36px;
  -webkit-border-radius: $border-radius !important;
  -moz-border-radius: $border-radius !important;
  -ms-border-radius: $border-radius !important;
  -o-border-radius: $border-radius !important;
}

.ng-select {
  min-width: 200px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.ng-dropdown-panel {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  padding: 18px;
  border: none !important;
  z-index: 1000 !important;
  border-radius: $border-radius !important;
  -webkit-border-radius: $border-radius !important;
  -moz-border-radius: $border-radius !important;
  -ms-border-radius: $border-radius !important;
  -o-border-radius: $border-radius !important;
  margin: 16px 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  & {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: .1s;
    padding: 12px 0;
    border-bottom: 1px solid #E1E6F8;
    -webkit-transition: .1s;
    -moz-transition: .1s;
    -ms-transition: .1s;
    -o-transition: .1s;
    color: #818181;
  }

  &:last-child {
    border-bottom: none;
  }

}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: $body-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: $body-color;
  font-weight: 700;

}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 700;
  color: $body-color !important;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: transparent !important;
}

.ng-select {
  &.ng-select-focused {
    &:not(.ng-select-opened)>.ng-select-container {
      box-shadow: none !important;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  color: $white;
}

.ng-select .ng-clear-wrapper {
  color: #818181;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 0;
}

.select2-container {

  .select2-selection--single {
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    height: 38px;

    &:focus {
      outline: none;
    }

    .select2-selection__rendered {
      line-height: 36px;
      padding-left: 12px;
    }

    .select2-selection__arrow {
      height: 34px;
      width: 34px;
      right: 3px;

      b {
        border-color: $gray-500 transparent transparent transparent;
        border-width: 6px 6px 0 6px;
      }
    }
  }
}

.select2-container--open {
  .select2-selection--single {

    .select2-selection__arrow {

      b {
        border-color: transparent transparent $gray-500 transparent !important;
        border-width: 0 6px 6px 6px !important;
      }
    }
  }
}

.select2-container--default {
  .select2-search--dropdown {
    padding: 10px;
    background-color: $dropdown-bg;

    .select2-search__field {
      border: 1px solid $input-border-color;
      background-color: $input-bg;
      color: $gray-600;
      outline: none;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
  }

  .select2-results__option[aria-selected=true] {
    background-color: $dropdown-link-active-bg;
    color: $dropdown-link-active-color;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: $dropdown-border-color;
  background-color: $dropdown-bg;
  box-shadow: $box-shadow;
}

.select2-search {
  input {
    border: 1px solid $gray-300;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: static !important;
}

.select2-container {
  .select2-selection--multiple {
    min-height: 38px;
    background-color: $input-bg;
    border: 1px solid $input-border-color !important;

    .select2-selection__rendered {
      padding: 2px 10px;
    }

    .select2-search__field {
      margin-top: 7px;
      border: 0;
    }

    .select2-selection__choice {
      background-color: $gray-200;
      border: 1px solid $gray-300;
      border-radius: 1px;
      padding: 0 7px;
    }
  }
}

.select2-container--default {
  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: $input-bg;
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-input {
  // height: 100%;
  bottom: 0;
  top: 0;

  input {
    height: 100%;
    line-height: 100%;
  }
}

.ng-select {
  .ng-select-container {
    background-color: $input-bg !important;
    color: $input-color !important;

    input {
      color: $input-color !important;
    }
  }


  .ng-dropdown-panel {
    .ng-option {
      background-color: transparent !important;
      color: $input-color !important;

      &.ng-option-marked {
        background-color: transparent !important;
        color: $dark !important;
      }
    }
  }
}





.ng-select.ng-select-single .ng-select-container {
  height: $input-height !important;
}

.ng-select.ng-select-multiple .ng-select-container {
  min-height: $input-height !important;
}





.ng-select.sm.ng-select-single .ng-select-container,
.ng-select.sm.ng-select-multiple .ng-select-container {
  & {
    height: calc($input-height-sm + $input-padding-y-sm) !important;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
  }



  .ng-select .ng-clear-wrapper {
    width: 40px !important;
  }

  .ng-arrow-wrapper .ng-arrow::after {
    content: "\e905";
    font-family: "aya" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    color: inherit;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    font-size: .8rem;
    transition: transform .3s;
    -webkit-transition: transform .3s;
    -moz-transition: transform .3s;
    -ms-transition: transform .3s;
    -o-transition: transform .3s;
  }
}

.ng-select.sm.ng-select-single .ng-dropdown-panel,
.ng-select.sm.ng-select-multiple .ng-dropdown-panel {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  padding: 12px !important;
  margin: 10px 0 !important;

  .ng-option,
  .ng-placeholder,
  .ng-option.ng-option-selected.ng-option-marked .ng-option-label,
  .ng-option.ng-option-selected .ng-option-label {
    color: $info;
  }
}

.ng-select.sm.ng-select-single.ng-select-opened .ng-select-container,
.ng-select.sm.ng-select-multiple.ng-select-opened .ng-select-container {
  .ng-arrow-wrapper .ng-arrow::after {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

.ng-select.ng-select-single.primary .ng-select-container,
.ng-select.ng-select-multiple.primary .ng-select-container {
  background-color: $primary !important;
  color: $white !important;
  border: 0 !important;

  .ng-value-container .ng-value {
    color: $white !important;
    font-weight: $form-select-font-weight;
  }


  .ng-clear-wrapper {
    color: $white;
  }

  .ant-select-selection-placeholder,
  .ng-value-container .ng-placeholder {
    color: $white;
  }



}


.ng-select .ng-clear-wrapper {
  cursor: pointer;
  position: relative;
  width: 40px !important;
  margin-top: -2px;

  .ng-clear {
    font-size: 25px !important;
  }
}

.ng-arrow-wrapper {

  & {
    padding-inline: $input-padding-x;
  }

  .ng-arrow {
    & {
      border-width: 0 !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    &::after {
      content: "\ea29";
      font-family: boxicons !important;
      font-weight: 400;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      display: inline-block;
      color: inherit;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      font-size: 1.2rem;
    }
  }
}


.ng-select>.ng-select-container {
  border-color: transparent;
}

.ng-select.ng-select-opened>.ng-select-container {
  border-color: transparent;
}

.ant-form-item-has-error .ng-select.ng-select-single .ng-select-container,
.ant-form-item-has-error .ng-select.ng-select-multiple .ng-select-container {
  border-color: var(--ant-error-color) !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: #818181 !important;
  font-weight: $form-select-font-weight;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: transparent;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
}

.ng-select.ng-select-disabled .ng-select-container {
  background-color: #E8E8E8 !important;
  color: #a29f9f !important;

  .ng-value-container .ng-placeholder {
    font-weight: normal;
    opacity: .6;
  }
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 5px;
  color: #fff !important;
  padding: 4px;
  font-size: .7rem;
}

.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 700;
  color: $primary !important;
}


.ng-select.white {
  .ng-select-container {
    background-color: $white !important;
    color: $input-color !important;

    input {
      color: $input-color !important;
    }
  }

  .ng-value{
    font-size: .8rem;
  }

 
  .ng-dropdown-panel {
    .ng-option {
      background-color: transparent !important;
      color: $input-color !important;
      font-size: .8rem;
      &.ng-option-marked {
        background-color: transparent !important;
        color: $dark !important;
      }
    }
  }
}
