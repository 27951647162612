$grid-values: (
  auto-1fr-auto: auto 1fr auto,
  auto-1fr: auto 1fr,
  auto: auto,
  fr-fr: 1fr 1fr,
  fr-fr-fr: 1fr 1fr 1fr,
  five-three-five: 5fr 3fr 5fr,
  cols-8: repeat(8, 1fr),
  cols-7: repeat(7, 1fr),
  cols-6: repeat(6, 1fr),
  cols-5: repeat(5, 1fr),
  cols-4: repeat(4, 1fr),
  cols-3: repeat(3, 1fr),
  cols-2: repeat(2, 1fr),
  cols-1: 1fr,
);
$grid-col-values: (

  1: span 1,
  2: span 2,
  3: span 3,
  4: span 4,
  5: span 5,
  6: span 6,
  7: span 7,
 
  
);
.grid-col-full{
  grid-column: 1/-1;
}



$utilities: map-merge($utilities,
  ("max-w": (property: max-width,
      class: max-w,
      responsive: true,
      values: (100: 100%,
        150px: 150px,
        200px: 200px,
        300px: 300px,
        400px: 400px,
        500px: 500px,
        600px: 600px,
        800px: 800px,
        1100px: 1100px,
        1440px: 1440px,
        1640px: 1640px,
        1128px: 1128px,

      )),
    "min-w": (property: min-width,
      class: min-w,
      responsive: true,
      values: (100: 100%,
        75px: 75px,
        100px: 100px,
        200px: 200px,
        300px: 300px,
        400px: 400px,
        500px: 500px,
        555px: 555px,
        800px: 800px,
        1440px: 1440px,
        1128px: 1128px,

      )),
    "min-vh": (property: min-height,
      class: min-vh,
      responsive: true,
      values: (
        25: 25vh,
        50: 50vh,
        75: 75vh,
        100: 100vh,
      )),
    "min-h": (property: min-height,
      class: min-h,
      responsive: true,
      values: (
        75px: 75px,
        100vh: 100vh,
        50vh: 50vh,
        25vh: 25vh,
        170px: 170px,
        200px: 200px,
        300px: 300px,
        400px: 400px,
        500px: 500px,
        555px: 555px,
        800px: 800px,
        1440px: 1440px,
        1128px: 1128px, 
      )),
    "grid-rows": (property: grid-template-rows,
      class: grid-rows,
      responsive: true,
      values: $grid-values),
    "grid-columns": (property: grid-template-columns,
      class: grid-columns,
      responsive: true,
      values: $grid-values),
    "grid-col": (property: grid-column,
      class: grid-col,
      responsive: true,
      values: $grid-col-values),
    

    "minus-m-x": (property: margin-right margin-left,
      class: minus-m-x,
      responsive: true,
      values: (0: 0,
        15px: -15px,
        20px: -20px,
        30px: -30px,
        10px: -10px,


      )),
    "w": (property: width,
      class: w,
      responsive: true,
      values: (100: 100%,
        10px: 10px,
        20px: 20px,
        30px: 30px,
        40px: 40px,
        50px: 50px,
        60px: 60px,
        70px: 70px,
        100px: 100px,
        200px: 200px,
        200: 200px,
        300px: 300px,
        400px: 400px,
        500px: 500px,
        555px: 555px,
        800px: 800px,
        1440px: 1440px,
        1128px: 1128px,

        auto: auto,

      )),
      "font-weight": (
        property: font-weight,
        class: fw,
        values: (
          light: $font-weight-light,
          lighter: $font-weight-lighter,
          normal: $font-weight-normal,
          bold: $font-weight-bold,
          semibold: $font-weight-semibold,
          bolder: $font-weight-bolder
        )
      ),
    "rounded": (property: border-radius,
      class: rounded,
      values: (null: 4px,
        0: 0,
        1: 2px,
        2: 4px,
        6px: 6px,
        3: 8px,
        4: 16px,
        5: 24px,
        circle: 50%,
        pill: $border-radius-pill)),
    "display": (responsive: true,
      print: true,
      property: display,
      class: d,
      values: inline inline-block block grid table table-row table-cell flex inline-flex none table-header-group table-row-group),
    "height": (property: height,
      responsive: true,
      class: h,
      values: (25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
        10px: 10px,
        15px: 50px,
        20px: 20px,
        25px: 25px,
        30px: 30px,
        40px: 40px,
        50px: 50px,
        60px: 60px,
        70px: 70px,
        80px: 80px,
        82px: 82px,
        200px: 200px,
        250px: 250px,
        300px: 300px,
        350px: 350px,
        400px: 400px,
      )),


    "border-width": (responsive: true,
      property: border-width,
      class: border,
      values: $border-widths),
    "max-width": (responsive: true,
      property: max-width,
      class: mw,
      values: (100: 100%, 290px: 290px, 1920px: 1920px)),
    "max-height": (responsive: true,
      property: max-height,
      class: mh,
      values: (100: 100%, 290px: 290px, 500px: 500px, 100vh: 100vh)),
    // -------------------------------
    "position": (responsive: true,
      print: true,
      property: position,
      values: static relative absolute fixed sticky),
    "top": (responsive: true,
      print: true,
      property: top,
      values: $position-values),
    "bottom": (responsive: true,
      print: true,
      property: bottom,
      values: $position-values),


    "translate-middle": (responsive: true,
      print: true,
      property: transform,
      class: translate-middle,
      values: (null: translate(-50%, -50%),
        x: translateX(-50%),
        y: translateY(-50%),
      )),
    "z-index": (responsive: true,
      print: true,
      property: z-index,
      class: z,
      values: (2: 2,
        3: 3,
        4: 4,
        5: 5,
        10: 10,
        50: 50,
        500: 500,
        1000: 1000,

      )),
     

  ),



);

@each $color, $value in $theme-colors {
  .bg-outline-#{$color} {
    background-color: rgba($value, 0.2) !important;
  }
  .bg-outline-#{$color}:hover {
    background-color: rgba($value, 0.3) !important;
  }
  .btn-outline-#{$color} {
    background-color: rgba($value, 0.2) !important;
  }
  .btn-outline-#{$color}:hover {
    background-color: rgba($value, 0.3) !important;
  }
}



// scss-docs-start btn-variant-mixin
@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
  $hover-color: color-contrast($hover-background),
  $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
}
// scss-docs-end btn-variant-mixin

