//
// _menu.scss
// 
$sidebar-padding-start: 1rem;
$sidebar-padding-end: 1rem;

@mixin side-padding {
  padding-right: $sidebar-padding-start;
  padding-left: $sidebar-padding-end;
  padding-inline-start: $sidebar-padding-start;
  padding-inline-end: $sidebar-padding-end;
}


.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility;
  }
}

.left-sidebar {
  & {
    display: grid;
    z-index: 1001;
    background: $sidebar-bg;
    margin-top: 0;
    top: 0;
    box-shadow: $box-shadow;
    position: sticky;
    width: 0;
    display: none;
    transform: translateX(-$sidebar-width);
    -webkit-transform: translateX(-$sidebar-width);
    -moz-transform: translateX(-$sidebar-width);
    -ms-transform: translateX(-$sidebar-width);
    -o-transform: translateX(-$sidebar-width);
  }

}

body.left-sider {
  .left-sidebar {
    position: fixed;
    left: 0;
    display: grid;
    width: $sidebar-width;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

// ? ========================

.vertical-menu {
  .logo-sm {
    display: none;
  }

  grid-template-columns: 100%;
  display: grid;
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  box-shadow: $box-shadow;

  .profile {
    height: $header-height;
    display: grid;
    vertical-align: middle;
    // justify-items: center;
    /* Center items horizontally */
    align-items: center;
    /* Center items vertically */
    max-width: 100%;
    padding: 5px 0;
    @include side-padding;
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}


#sidebar-menu {
  padding: 10px 0 30px 0;
  @include side-padding;

  .mm-active {
    >.has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    & {}

    &:after {
      content: "\ea29";
      font-family: boxicons !important;
      font-weight: 400;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      text-transform: none;
      display: block;
      float: right;
      transition: transform .2s;
      font-size: 1rem;
      margin-top: 5px;
      padding-inline: 5px;
    }
  }

  >ul {
    >li {

      & {
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      &:hover {
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;

        background-color: #EFEFEF;
        width: 100%;

      }
    }
  }

  ul {
    li {

      ul li {
        padding-inline-start: 15px;
      }

      & {
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        // padding-top: 5px;
        // padding-bottom: 5px;
        // margin-bottom: 10px;
      }

      &:hover {
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        // padding-top: 5px;
        // padding-bottom: 5px;
        background-color: #EFEFEF;
        a {
          color: $primary;
          width: 100%;
        }

      }

      a {
        min-width: 150px;
        display: inline-block;
        color: $sidebar-menu-item-color;
        font-weight: 600;
        position: relative;
        font-size: 1rem;
        transition: all .4s;
        width: 100%;


        i {
          display: inline-block;
          min-width: 1.75rem;
          padding-bottom: .125em;
          font-size: 1.25rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: $sidebar-menu-item-icon-color;
          transition: all .4s;
        }

        &.mm-active {
          &::before {
            display: none;
          }
        }

        &.mm-active {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        &:hover,
        &.active,
        &.mm-active {
          & {
            color: $primary;
            // background-color: $white;
            // padding-right: 10px;
            // padding-inline-start: 10px;
            font-weight: 700;

            i {
              color: $sidebar-menu-item-hover-color !important;
            }
          }

          .arrow_icon {
            opacity: 0;
          }



          i {
            color: $sidebar-menu-item-hover-color;
          }
        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;

        li {

          a {
            padding: .4rem 1.5rem .4rem 0;
            font-size: .8rem;
            color: $sidebar-menu-sub-item-color;

            &:hover {
              color: $sidebar-menu-item-hover-color;
            }
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: .4rem 1.5rem .4rem 4.5rem;
                font-size: 13px;


              }
            }
          }
        }
      }
    }

  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semibold;
}

.mm-active {
  & {
    color: $sidebar-menu-item-active-color !important;
    background-color: #EFEFEF;
    min-width: 150px;
    width: 100% !important;
    display: inline-block !important;
    // margin-bottom: 10px;
    // padding: 5px 0;
    // padding-right: 15px;
    // padding-inline-start: 15px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    position: relative;
  }

  // &::before {
  //   position: absolute;
  //   right: 0;
  //   z-index: 2;
  //   inset-inline-start: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   height: 50%;
  //   width: 5px;
  //   background-color: #4B4B4B;
  //   content: '';
  //   border-radius: 3px;
  //   -webkit-border-radius: 3px;
  //   -moz-border-radius: 3px;
  //   -ms-border-radius: 3px;
  //   -o-border-radius: 3px;
  // }

  >a {
    color: $sidebar-menu-item-active-color !important;

    .arrow_icon {
      opacity: 0;
    }

    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }

  .active {
    color: $primary !important;
    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }

  >i {
    color: $sidebar-menu-item-active-color !important;
  }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collpsed {

  .ribbon{
    display: none;
  }
  .navbar-brand {
    text-align: center;

    .top_text {
      display: none !important;
    }
  }

  .logo-lg {
    display: none;
  }

  .logo-sm {
    display: block;
  }

  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // Side menu
  .vertical-menu {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    .profile {
      padding: 0;
      text-align: center;

      .dropdown-toggle {
        grid-auto-flow: row !important;

        img {
          margin: auto;
        }

        .text,
        i {
          display: none !important;
        }

      }
    }

    // scroll

    perfect-scrollbar {
      overflow: visible;

      .ps {
        overflow: visible !important;
      }

      .ps__rail-y {
        display: none !important;
      }
    }

    position: fixed;
    width: $sidebar-collapsed-width !important;
    z-index: 5;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      padding: 0;

      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }


      >ul {
        >li {
          position: relative;
          white-space: nowrap;
          padding: 0;

          >a {
            min-width: 0 !important;
            padding: 15px 15px;
            margin: auto;
            min-height: 55px;
            transition: none;
            color: #fff;

            .arrow_icon {
              opacity: 0;
              display: none !important;
            }

            i {
              color: $white !important;
            }

            &.active {
              i {
                color: $sidebar-menu-item-hover-color !important;
              }
            }

            &:hover,
            &:focus {

              color: $sidebar-menu-item-hover-color;

              i {
                color: $white !important;
              }
            }


            i {
              font-size: 1.45rem;
              margin-left: 4px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            >a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width}) !important;
              color: $white !important;
              background-color: darken($sidebar-bg, 4%);
              transition: none;
              min-width: 0 !important;
              border-radius: 0 !important;

              i {
                color: $white;
              }

              span {
                display: inline;
              }
            }

            >ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
              }

              a {
                & {
                  border-radius: 0 !important;
                  box-shadow: none;
                  padding: 8px 20px;
                  position: relative;
                  width: 190px;
                  min-width: 0 !important;
                  z-index: 6;
                  color: $sidebar-menu-sub-item-color;
                  -webkit-border-radius: 0 !important;
                  -moz-border-radius: 0 !important;
                  -ms-border-radius: 0 !important;
                  -o-border-radius: 0 !important;
                }

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              >ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            >a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $white;

              i {
                color: #000;
              }
            }
          }
        }
      }
    }

  }


  .mm-active {
    & {
      color: $sidebar-menu-item-active-color !important;
      background-color: #EFEFEF;
      min-width: 0;
      width: 95% !important;
      display: inline-block !important;
      // margin-bottom: 10px;
      // padding: 5px 0;
      // padding-right: 15px;
      // padding-inline-start: 15px;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
      position: relative;
    }

    &::before {
      position: absolute;
      right: 0;
      z-index: 2;
      inset-inline-start: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50%;
      width: 5px;
      background-color: #4B4B4B;
      content: '';
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
    }

    >a {
      color: $sidebar-menu-item-active-color !important;

      .arrow_icon {
        opacity: 0;
      }

      i {
        color: $sidebar-menu-item-active-color !important;
      }
    }

    .active {
      color: $sidebar-menu-item-active-color !important;

      i {
        color: $sidebar-menu-item-active-color !important;
      }
    }

    >i {
      color: $sidebar-menu-item-active-color !important;
    }
  }
}


body[data-sidebar="dark"] {
  .vertical-menu {
    background: $sidebar-dark-bg;
  }

  #sidebar-menu {

    ul {
      li {
        a {
          color: $sidebar-dark-menu-item-color;

          i {
            color: $sidebar-dark-menu-item-icon-color;
          }

          &:hover {
            color: $sidebar-dark-menu-item-hover-color;

            i {
              color: $sidebar-dark-menu-item-hover-color;
            }
          }
        }

        ul.sub-menu {
          li {

            a {
              color: $sidebar-dark-menu-sub-item-color;

              &:hover {
                color: $sidebar-dark-menu-item-hover-color;
              }
            }
          }
        }
      }
    }
  }

  // Enlarge menu
  &.vertical-collpsed {
    min-height: 1760px;

    // Side menu
    .vertical-menu {

      // Sidebar Menu
      #sidebar-menu {

        >ul {
          >li {

            &:hover {
              >a {
                background: lighten($sidebar-dark-bg, 2%);
                color: $sidebar-dark-menu-item-hover-color;

                i {
                  color: $sidebar-dark-menu-item-hover-color;
                }
              }

              >ul {
                a {
                  color: $sidebar-dark-menu-sub-item-color;

                  &:hover {
                    color: $sidebar-dark-menu-item-hover-color;
                  }
                }
              }
            }
          }

          ul {
            background-color: $sidebar-dark-bg;
          }

        }

        ul {

          li {
            &.mm-active .active {
              color: $sidebar-dark-menu-item-active-color !important;

              i {
                color: $sidebar-dark-menu-item-active-color !important;
              }
            }
          }
        }

      }


    }
  }

  .mm-active {
    color: $sidebar-dark-menu-item-active-color !important;

    >a {
      color: $sidebar-dark-menu-item-active-color !important;

      i {
        color: $sidebar-dark-menu-item-active-color !important;
      }
    }

    >i {
      color: $sidebar-dark-menu-item-active-color !important;
    }

    .active {
      color: $sidebar-dark-menu-item-active-color !important;

      i {
        color: $sidebar-dark-menu-item-active-color !important;
      }
    }
  }

  .menu-title {
    color: $sidebar-dark-menu-item-icon-color;
  }
}


body[data-layout="horizontal"] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-brand-box {
    width: $sidebar-width-sm;
  }

  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }

  .main-content {
    margin-left: $sidebar-width-sm;
  }

  .footer {
    left: $sidebar-width-sm;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: lighten($sidebar-dark-bg, 2%);
      }

      a {
        i {
          display: block;
        }
      }

      ul.sub-menu {
        li {
          a {
            padding-left: 1.5rem;
          }

          ul.sub-menu li a {
            padding-left: 1.5rem;
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }

    .vertical-menu {
      #sidebar-menu {
        text-align: left;

        >ul {
          >li {
            >a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// colored sidebar

body[data-sidebar="colored"] {
  .vertical-menu {
    background-color: $primary;
  }

  .navbar-brand-box {
    background-color: $primary;

    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;

    >a {
      color: $white !important;

      i {
        color: $white !important;
      }
    }

    >i,
    .active {
      color: $white !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);

          i {
            color: rgba($white, 0.6);
          }

          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }

          &:hover {
            color: $white;

            i {
              color: $white;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, .5);

              &:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        >ul {
          >li {
            &:hover>a {
              background-color: lighten($primary, 2%);
              color: $white;

              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $sidebar-menu-item-active-color !important;
              }
            }

            ul.sub-menu {
              li {
                a {
                  &:hover {
                    color: $sidebar-menu-item-active-color;
                  }
                }

                &.mm-active {
                  color: $sidebar-menu-item-active-color !important;

                  >a {
                    color: $sidebar-menu-item-active-color !important;

                    i {
                      color: $sidebar-menu-item-active-color !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


// colored sidebar

body[data-sidebar="colored"] {
  .vertical-menu {
    background-color: $primary;
  }

  .navbar-brand-box {
    background-color: $primary;

    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;

    >a {
      color: $white !important;

      i {
        color: $white !important;
      }
    }

    >i,
    .active {
      color: $white !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);

          i {
            color: rgba($white, 0.6);
          }

          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }

          &:hover {
            color: $white;

            i {
              color: $white;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, .5);

              &:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        >ul {
          >li {
            &:hover>a {
              background-color: lighten($primary, 2%);
              color: $white;

              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $sidebar-menu-item-active-color !important;
              }
            }

            ul.sub-menu {
              li {
                a {
                  &:hover {
                    color: $sidebar-menu-item-active-color;
                  }
                }

                &.mm-active {
                  color: $sidebar-menu-item-active-color !important;

                  >a {
                    color: $sidebar-menu-item-active-color !important;

                    i {
                      color: $sidebar-menu-item-active-color !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}