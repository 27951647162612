// --------------------------------------------------------------
// Preview Thumbnails
// --------------------------------------------------------------

@import './settings';

.plyr__preview-thumb {
  background-color: $plyr-preview-background;
  border-radius: $plyr-preview-radius;
  bottom: 100%;
  box-shadow: $plyr-preview-shadow;
  margin-bottom: calc(#{$plyr-preview-padding} * 2);
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;

  &--is-shown {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  // The background triangle
  &::before {
    border-left: $plyr-preview-arrow-size solid transparent;
    border-right: $plyr-preview-arrow-size solid transparent;
    border-top: $plyr-preview-arrow-size solid $plyr-preview-background;
    bottom: calc(#{$plyr-preview-arrow-size} * -1);
    content: '';
    height: 0;
    left: calc(50% + var(--preview-arrow-offset));
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2;
  }

  &__image-container {
    background: $plyr-preview-image-background;
    border-radius: calc(#{$plyr-preview-radius} - 1px);
    overflow: hidden;
    position: relative;
    z-index: 0;

    img,
    &::after {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::after {
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px rgba(#000, 15%);
      content: '';
      pointer-events: none;
    }

    img {
      // Non sprite images are 100%. Sprites will have their size applied by JavaScript
      max-height: none;
      max-width: none;
    }
  }

  // Seek time text
  &__time-container {
    background: $plyr-preview-time-container-background;
    border-bottom-left-radius: calc(#{$plyr-preview-radius} - 1px);
    border-bottom-right-radius: calc(#{$plyr-preview-radius} - 1px);
    bottom: 0;
    left: 0;
    line-height: 1.1;
    padding: $plyr-preview-time-container-padding;
    position: absolute;
    right: 0;
    z-index: 3;

    span {
      color: $plyr-preview-time-color;
      font-size: $plyr-preview-time-font-size;
    }
  }
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto; // Required when video is different dimensions to container (e.g. fullscreen)
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;

  &--is-shown {
    opacity: 1;
  }

  img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
