// --------------------------------------------------------------
// Slider inputs - <input type="range">
// --------------------------------------------------------------

.plyr--full-ui input[type='range'] {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: calc(#{$plyr-range-thumb-height} * 2);

  // `color` property is used in JS to populate lower fill for WebKit
  color: $plyr-range-fill-background;
  display: block;
  height: calc((#{$plyr-range-thumb-active-shadow-width} * 2) + #{$plyr-range-thumb-height});
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;

  &::-webkit-slider-runnable-track {
    @include plyr-range-track;

    background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
  }

  &::-webkit-slider-thumb {
    @include plyr-range-thumb;

    appearance: none;
    margin-top: calc(((#{$plyr-range-thumb-height} - #{$plyr-range-track-height}) / 2) * -1);
  }

  // Mozilla
  &::-moz-range-track {
    @include plyr-range-track;
  }

  &::-moz-range-thumb {
    @include plyr-range-thumb;
  }

  &::-moz-range-progress {
    background: currentColor;
    border-radius: calc(#{$plyr-range-track-height} / 2);
    height: $plyr-range-track-height;
  }

  // Microsoft
  &::-ms-track {
    @include plyr-range-track;

    color: transparent;
  }

  &::-ms-fill-upper {
    @include plyr-range-track;
  }

  &::-ms-fill-lower {
    @include plyr-range-track;

    background: currentColor;
  }

  &::-ms-thumb {
    @include plyr-range-thumb;

    // For some reason, Edge uses the -webkit margin above
    margin-top: 0;
  }

  &::-ms-tooltip {
    display: none;
  }

  // Focus styles
  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    &::-webkit-slider-runnable-track {
      @include plyr-focus-visible;
    }

    &::-moz-range-track {
      @include plyr-focus-visible;
    }

    &::-ms-track {
      @include plyr-focus-visible;
    }
  }
}
