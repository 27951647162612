$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$isax-notification-bing1: "\eca5";
$isax-bag-timer: "\ec9b";
$isax-barcode: "\ec9c";
$isax-shop1: "\ec9d";
$isax-shop-remove1: "\ec9e";
$isax-bag: "\ec9f";
$isax-bag-cross: "\eca0";
$isax-bag-cross-1: "\eca1";
$isax-bag-happy: "\eca2";
$isax-bag-tick: "\eca3";
$isax-bag-tick-2: "\eca4";
$isax-arrange-circle: "\eca6";
$isax-arrange-circle-2: "\eca7";
$isax-arrange-square: "\eca8";
$isax-arrange-square-2: "\eca9";
$isax-arrow-2: "\ecaa";
$isax-arrow-3: "\ecab";
$isax-arrow-bottom: "\ecac";
$isax-arrow-circle-down: "\ecad";
$isax-arrow-circle-left: "\ecae";
$isax-arrow-circle-right: "\ecaf";
$isax-arrow-down: "\ecb0";
$isax-arrow-down-1: "\ecb1";
$isax-arrow-down-2: "\ecb2";
$isax-arrow-left: "\ecb3";
$isax-arrow-left-1: "\ecb4";
$isax-arrow-left-2: "\ecb5";
$isax-arrow-left-3: "\ecb6";
$isax-arrow-right: "\ecb7";
$isax-arrow-right-1: "\ecb8";
$isax-arrow-right-2: "\ecb9";
$isax-arrow-right-3: "\ecba";
$isax-arrow-square-down: "\ecbb";
$isax-arrow-square-left: "\ecbc";
$isax-arrow-square-right: "\ecbd";
$isax-arrow-square-up: "\ecbe";
$isax-arrow-swap-horizontal: "\ecbf";
$isax-arrow-up: "\ecc0";
$isax-arrow-up-1: "\ecc1";
$isax-arrow-up-2: "\ecc2";
$isax-arrow-up-3: "\ecc3";
$isax-back-square: "\ecc4";
$isax-bitcoin-btc-1: "\e900";
$isax-bitcoin-card: "\e901";
$isax-bitcoin-convert: "\e902";
$isax-bitcoin-refresh: "\e903";
$isax-blend: "\e904";
$isax-blend-2: "\e905";
$isax-blogger: "\e906";
$isax-bluetooth: "\e907";
$isax-bluetooth-2: "\e908";
$isax-bluetooth-circle: "\e909";
$isax-bluetooth-rectangle: "\e90a";
$isax-blur: "\e90b";
$isax-blur-1: "\e90c";
$isax-book: "\e90d";
$isax-book-1: "\e90e";
$isax-bookmark: "\e90f";
$isax-bookmark-2: "\e910";
$isax-book-saved: "\e911";
$isax-book-square: "\e912";
$isax-bootsrap: "\e913";
$isax-box-2: "\e916";
$isax-box-add: "\e917";
$isax-box-remove: "\e918";
$isax-box-search: "\e919";
$isax-box-tick: "\e91a";
$isax-box-time: "\e91b";
$isax-briefcase: "\e91c";
$isax-brifecase-cross: "\e91d";
$isax-brifecase-tick: "\e91e";
$isax-brifecase-timer: "\e91f";
$isax-broom: "\e920";
$isax-brush: "\e921";
$isax-brush-1: "\e922";
$isax-brush-2: "\e923";
$isax-brush-3: "\e924";
$isax-bubble: "\e925";
$isax-bucket-circle: "\e926";
$isax-bucket-circle-1: "\e927";
$isax-bucket-square: "\e928";
$isax-bucket-square-1: "\e929";
$isax-building: "\e92a";
$isax-building-3: "\e92b";
$isax-building-4: "\e92c";
$isax-buildings: "\e92d";
$isax-buildings-2: "\e92e";
$isax-buliding: "\e92f";
$isax-bus: "\e930";
$isax-buy-crypto: "\e931";
$isax-cake: "\e932";
$isax-calculator: "\e933";
$isax-calendar: "\e934";
$isax-calendar-1: "\e935";
$isax-calendar-2: "\e936";
$isax-calendar-add: "\e937";
$isax-calendar-circle: "\e938";
$isax-calendar-edit: "\e939";
$isax-calendar-remove: "\e93a";
$isax-calendar-search: "\e93b";
$isax-calendar-tick: "\e93c";
$isax-call: "\e93d";
$isax-call-add: "\e93e";
$isax-call-calling: "\e93f";
$isax-call-incoming: "\e940";
$isax-call-minus: "\e941";
$isax-call-outgoing: "\e942";
$isax-call-received: "\e943";
$isax-call-remove: "\e944";
$isax-call-slash: "\e945";
$isax-camera: "\e946";
$isax-camera-slash: "\e947";
$isax-candle: "\e948";
$isax-candle-2: "\e949";
$isax-car: "\e94a";
$isax-card: "\e94b";
$isax-card-add: "\e94c";
$isax-cardano-ada: "\e94d";
$isax-card-coin: "\e94e";
$isax-card-edit: "\e94f";
$isax-card-pos: "\e950";
$isax-card-receive: "\e951";
$isax-card-remove: "\e952";
$isax-card-remove-1: "\e953";
$isax-cards: "\e954";
$isax-card-send: "\e955";
$isax-card-slash: "\e956";
$isax-card-tick: "\e957";
$isax-card-tick-1: "\e958";
$isax-category: "\e959";
$isax-category-2: "\e95a";
$isax-cd: "\e95b";
$isax-celo-celo: "\e95c";
$isax-celsius-cel: "\e95d";
$isax-chainlink-link: "\e95e";
$isax-chart: "\e95f";
$isax-chart-1: "\e960";
$isax-chart-2: "\e961";
$isax-chart-3: "\e962";
$isax-chart-21: "\e963";
$isax-chart-square: "\e964";
$isax-chart-success: "\e965";
$isax-check: "\e966";
$isax-chrome: "\e967";
$isax-civic-cvc: "\e968";
$isax-clipboard: "\e969";
$isax-clipboard-close: "\e96a";
$isax-clipboard-export: "\e96b";
$isax-clipboard-import: "\e96c";
$isax-clipboard-text: "\e96d";
$isax-clipboard-tick: "\e96e";
$isax-clock: "\e96f";
$isax-clock-1: "\e970";
$isax-close-circle: "\e971";
$isax-close-square: "\e972";
$isax-cloud: "\e973";
$isax-cloud-add: "\e974";
$isax-cloud-change: "\e975";
$isax-cloud-connection: "\e976";
$isax-cloud-cross: "\e977";
$isax-cloud-drizzle: "\e978";
$isax-cloud-fog: "\e979";
$isax-cloud-lightning: "\e97a";
$isax-cloud-minus: "\e97b";
$isax-cloud-notif: "\e97c";
$isax-cloud-plus: "\e97d";
$isax-cloud-remove: "\e97e";
$isax-cloud-snow: "\e97f";
$isax-cloud-sunny: "\e980";
$isax-code: "\e981";
$isax-code-1: "\e982";
$isax-code-circle: "\e983";
$isax-coffee: "\e984";
$isax-coin: "\e985";
$isax-coin-1: "\e986";
$isax-colorfilter: "\e987";
$isax-colors-square: "\e988";
$isax-colors-square-1: "\e989";
$isax-color-swatch: "\e98a";
$isax-command: "\e98b";
$isax-command-square: "\e98c";
$isax-component: "\e98d";
$isax-component-1: "\e98e";
$isax-computing: "\e98f";
$isax-convert: "\e990";
$isax-convert-3d-cube: "\e991";
$isax-convertshape: "\e992";
$isax-convertshape-2: "\e993";
$isax-copy: "\e994";
$isax-copyright: "\e995";
$isax-copy-success: "\e996";
$isax-courthouse: "\e997";
$isax-cpu: "\e998";
$isax-cpu-charge: "\e999";
$isax-cpu-setting: "\e99a";
$isax-creative-commons: "\e99b";
$isax-crop: "\e99c";
$isax-crown: "\e99d";
$isax-crown-1: "\e99e";
$isax-cup: "\e99f";
$isax-dai-dai: "\e9a0";
$isax-danger: "\e9a1";
$isax-dash-dash: "\e9a2";
$isax-data: "\e9a3";
$isax-data-2-path1: "\e9a4";
$isax-data-2-path2: "\e9a5";
$isax-data-2-path3: "\e9a6";
$isax-data-2-path4: "\e9a7";
$isax-data-2-path5: "\e9a8";
$isax-data-2-path6: "\e9a9";
$isax-data-2-path7: "\e9aa";
$isax-data-2-path8: "\e9ab";
$isax-decred-dcr: "\e9ac";
$isax-dent-dent: "\e9ad";
$isax-designtools: "\e9ae";
$isax-device-message: "\e9af";
$isax-devices: "\e9b0";
$isax-devices-1: "\e9b1";
$isax-diagram: "\e9b2";
$isax-direct: "\e9b4";
$isax-directbox-default: "\e9b5";
$isax-directbox-notif: "\e9b6";
$isax-directbox-receive: "\e9b7";
$isax-directbox-send: "\e9b8";
$isax-direct-down: "\e9b9";
$isax-direct-inbox: "\e9ba";
$isax-direct-left: "\e9bb";
$isax-direct-normal: "\e9bc";
$isax-direct-notification: "\e9bd";
$isax-direct-right: "\e9be";
$isax-direct-send: "\e9bf";
$isax-direct-up: "\e9c0";
$isax-discount-circle: "\e9c1";
$isax-discount-shape: "\e9c2";
$isax-discover: "\e9c3";
$isax-discover-1: "\e9c4";
$isax-dislike: "\e9c5";
$isax-document: "\e9c6";
$isax-document-1: "\e9c7";
$isax-document-cloud: "\e9c8";
$isax-document-code: "\e9c9";
$isax-document-code-2: "\e9ca";
$isax-document-copy: "\e9cb";
$isax-document-download: "\e9cc";
$isax-document-favorite: "\e9cd";
$isax-document-filter: "\e9ce";
$isax-document-forward: "\e9cf";
$isax-document-like: "\e9d0";
$isax-document-previous: "\e9d1";
$isax-document-sketch: "\e9d2";
$isax-document-text: "\e9d3";
$isax-document-text-1: "\e9d4";
$isax-document-upload: "\e9d5";
$isax-dollar-circle: "\e9d6";
$isax-dollar-square: "\e9d7";
$isax-dribbble: "\e9d8";
$isax-driver: "\e9d9";
$isax-driver-2: "\e9da";
$isax-driver-refresh: "\e9db";
$isax-driving: "\e9dc";
$isax-drop: "\e9dd";
$isax-dropbox: "\e9de";
$isax-edit: "\e9df";
$isax-edit-2: "\e9e0";
$isax-educare-ekt: "\e9e1";
$isax-electricity: "\e9e2";
$isax-element-1: "\e9e3";
$isax-element-2: "\e9e4";
$isax-element-3: "\e9e5";
$isax-element-4: "\e9e6";
$isax-element-equal: "\e9e7";
$isax-element-plus: "\e9e8";
$isax-emercoin-emc: "\e9e9";
$isax-emoji-happy: "\e9ea";
$isax-emoji-normal: "\e9eb";
$isax-emoji-sad: "\e9ec";
$isax-empty-wallet: "\e9ed";
$isax-empty-wallet-add: "\e9ee";
$isax-empty-wallet-change: "\e9ef";
$isax-empty-wallet-remove: "\e9f0";
$isax-empty-wallet-tick: "\e9f1";
$isax-empty-wallet-time: "\e9f2";
$isax-enjin-coin-enj: "\e9f3";
$isax-eos-eos: "\e9f4";
$isax-eraser: "\e9f5";
$isax-eraser-1: "\e9f6";
$isax-eraser-2: "\e9f7";
$isax-ethereum-eth: "\e9f8";
$isax-ethereum-classic-etc: "\e9f9";
$isax-export: "\e9fa";
$isax-export-1: "\e9fb";
$isax-export-2: "\e9fc";
$isax-export-3: "\e9fd";
$isax-external-drive: "\e9fe";
$isax-eye: "\e9ff";
$isax-eye-slash: "\ea00";
$isax-facebook: "\ea01";
$isax-fatrows: "\ea02";
$isax-favorite-chart: "\ea03";
$isax-figma: "\ea04";
$isax-figma-1: "\ea05";
$isax-filter: "\ea06";
$isax-filter-add: "\ea07";
$isax-filter-edit: "\ea08";
$isax-filter-remove: "\ea09";
$isax-filter-search: "\ea0a";
$isax-filter-square: "\ea0b";
$isax-filter-tick: "\ea0c";
$isax-finger-cricle: "\ea0d";
$isax-finger-scan: "\ea0e";
$isax-firstline: "\ea0f";
$isax-flag: "\ea10";
$isax-flag-2: "\ea11";
$isax-flash: "\ea12";
$isax-flash-1: "\ea13";
$isax-flash-circle: "\ea14";
$isax-flash-circle-1: "\ea15";
$isax-flash-circle-2: "\ea16";
$isax-flash-slash: "\ea17";
$isax-folder: "\ea18";
$isax-folder-2: "\ea19";
$isax-folder-add: "\ea1a";
$isax-folder-cloud: "\ea1b";
$isax-folder-connection: "\ea1c";
$isax-folder-cross: "\ea1d";
$isax-folder-favorite: "\ea1e";
$isax-folder-minus: "\ea1f";
$isax-folder-open: "\ea20";
$isax-format-circle: "\ea21";
$isax-format-square: "\ea22";
$isax-forward: "\ea23";
$isax-forward-5-seconds: "\ea24";
$isax-forward-10-seconds: "\ea25";
$isax-forward-15-seconds: "\ea26";
$isax-forward-item: "\ea27";
$isax-forward-item-1: "\ea28";
$isax-forward-square: "\ea29";
$isax-frame: "\ea2a";
$isax-frame-1: "\ea2b";
$isax-frame-2: "\ea2c";
$isax-frame-3: "\ea2d";
$isax-frame-4: "\ea2e";
$isax-frame-5: "\ea2f";
$isax-frame-6: "\ea30";
$isax-frame-7: "\ea31";
$isax-framer: "\ea32";
$isax-ftx-token-ftt: "\ea33";
$isax-gallery: "\ea34";
$isax-gallery-add: "\ea35";
$isax-gallery-edit: "\ea36";
$isax-gallery-export: "\ea37";
$isax-gallery-favorite: "\ea38";
$isax-gallery-import: "\ea39";
$isax-gallery-remove: "\ea3a";
$isax-gallery-slash: "\ea3b";
$isax-gallery-tick: "\ea3c";
$isax-game: "\ea3d";
$isax-gameboy: "\ea3e";
$isax-gas-station: "\ea3f";
$isax-gemini: "\ea40";
$isax-gemini-2: "\ea41";
$isax-ghost: "\ea42";
$isax-gift: "\ea43";
$isax-glass: "\ea44";
$isax-glass-1: "\ea45";
$isax-global: "\ea46";
$isax-global-edit: "\ea47";
$isax-global-refresh: "\ea48";
$isax-global-search: "\ea49";
$isax-google: "\ea4a";
$isax-google-1: "\ea4b";
$isax-google-play: "\ea4c";
$isax-gps: "\ea4d";
$isax-gps-slash: "\ea4e";
$isax-grammerly: "\ea4f";
$isax-graph: "\ea50";
$isax-grid-2: "\ea51";
$isax-grid-3: "\ea52";
$isax-grid-4: "\ea53";
$isax-grid-5: "\ea54";
$isax-grid-6: "\ea55";
$isax-grid-7: "\ea56";
$isax-grid-8: "\ea57";
$isax-grid-9: "\ea58";
$isax-grid-edit: "\ea59";
$isax-grid-eraser: "\ea5a";
$isax-grid-lock: "\ea5b";
$isax-group: "\ea5c";
$isax-group-1: "\ea5d";
$isax-group-2: "\ea5e";
$isax-group-3: "\ea5f";
$isax-group-4: "\ea60";
$isax-group-5: "\ea61";
$isax-group-6: "\ea62";
$isax-group-7: "\ea63";
$isax-group-8: "\ea64";
$isax-group-9: "\ea65";
$isax-group-10: "\ea66";
$isax-group-11: "\ea67";
$isax-happyemoji: "\ea68";
$isax-harmony-one: "\ea69";
$isax-hashtag: "\ea6a";
$isax-hashtag-down: "\ea6b";
$isax-hashtag-up: "\ea6c";
$isax-headphone: "\ea6d";
$isax-headphones: "\ea6e";
$isax-health: "\ea6f";
$isax-heart: "\ea70";
$isax-heart-add: "\ea71";
$isax-heart-circle: "\ea72";
$isax-heart-edit: "\ea73";
$isax-heart-remove: "\ea74";
$isax-heart-search: "\ea75";
$isax-heart-slash: "\ea76";
$isax-heart-tick: "\ea77";
$isax-hedera-hashgraph-hbar: "\ea78";
$isax-hex-hex: "\ea79";
$isax-hierarchy: "\ea7a";
$isax-hierarchy-2: "\ea7b";
$isax-hierarchy-3: "\ea7c";
$isax-hierarchy-square: "\ea7d";
$isax-hierarchy-square-2: "\ea7e";
$isax-hierarchy-square-3: "\ea7f";
$isax-home: "\ea80";
$isax-home-2: "\ea81";
$isax-home-hashtag: "\ea82";
$isax-home-trend-down: "\ea83";
$isax-home-trend-up: "\ea84";
$isax-home-wifi: "\ea85";
$isax-hospital: "\ea86";
$isax-house: "\ea87";
$isax-house-2: "\ea88";
$isax-html-3: "\ea89";
$isax-html-5: "\ea8a";
$isax-huobi-token-ht: "\ea8b";
$isax-icon-icx: "\ea8c";
$isax-illustrator: "\ea8d";
$isax-image: "\ea8e";
$isax-import: "\ea8f";
$isax-import-1: "\ea90";
$isax-import-2: "\ea91";
$isax-info-circle: "\ea92";
$isax-information: "\ea93";
$isax-instagram: "\ea94";
$isax-iost-iost: "\ea95";
$isax-java-script: "\ea96";
$isax-js: "\ea97";
$isax-judge: "\ea98";
$isax-kanban: "\ea99";
$isax-key: "\ea9a";
$isax-keyboard: "\ea9b";
$isax-keyboard-open: "\ea9c";
$isax-key-square: "\ea9d";
$isax-kyber-network-knc: "\ea9e";
$isax-lamp: "\ea9f";
$isax-lamp-1: "\eaa0";
$isax-lamp-charge: "\eaa1";
$isax-lamp-on: "\eaa2";
$isax-lamp-slash: "\eaa3";
$isax-language-circle: "\eaa4";
$isax-language-square: "\eaa5";
$isax-layer: "\eaa6";
$isax-level: "\eaa7";
$isax-lifebuoy: "\eaa8";
$isax-like-1: "\eaaa";
$isax-like-dislike: "\eaab";
$isax-like-shapes: "\eaac";
$isax-like-tag: "\eaad";
$isax-link: "\eaae";
$isax-link-1: "\eaaf";
$isax-link-2: "\eab0";
$isax-link-21: "\eab1";
$isax-link-circle: "\eab2";
$isax-link-square: "\eab3";
$isax-litecoinltc: "\eab4";
$isax-location: "\eab5";
$isax-location-add: "\eab6";
$isax-location-cross: "\eab7";
$isax-location-minus: "\eab8";
$isax-location-slash: "\eab9";
$isax-location-tick: "\eaba";
$isax-lock: "\eabb";
$isax-lock-1: "\eabc";
$isax-lock-circle: "\eabd";
$isax-lock-slash: "\eabe";
$isax-login: "\eabf";
$isax-login-1: "\eac0";
$isax-logout: "\eac1";
$isax-logout-1: "\eac2";
$isax-lovely: "\eac3";
$isax-magicpen: "\eac4";
$isax-magic-star: "\eac5";
$isax-main-component: "\eac6";
$isax-main-component-1: "\eac7";
$isax-maker-mkr: "\eac8";
$isax-man: "\eac9";
$isax-map: "\eaca";
$isax-map-1: "\eacb";
$isax-mask: "\eacc";
$isax-mask-1: "\eacd";
$isax-mask-2: "\eace";
$isax-mask-3: "\eacf";
$isax-math: "\ead0";
$isax-maximize: "\ead1";
$isax-maximize-1: "\ead2";
$isax-maximize-2: "\ead3";
$isax-maximize-3: "\ead4";
$isax-maximize-4: "\ead5";
$isax-maximize-21: "\ead6";
$isax-medal: "\ead7";
$isax-medal-star: "\ead8";
$isax-menu: "\ead9";
$isax-menu-1: "\eada";
$isax-menu-board: "\eadb";
$isax-message: "\eadc";
$isax-message-2: "\eadd";
$isax-message-add-1: "\eadf";
$isax-message-circle: "\eae0";
$isax-message-edit: "\eae1";
$isax-message-favorite: "\eae2";
$isax-message-minus: "\eae3";
$isax-message-notif: "\eae4";
$isax-message-programming: "\eae5";
$isax-message-question: "\eae6";
$isax-messages: "\eae8";
$isax-messages-1: "\eae9";
$isax-messages-2: "\eaea";
$isax-messages-3: "\eaeb";
$isax-message-search: "\eaec";
$isax-message-square: "\eaed";
$isax-message-text: "\eaee";
$isax-message-text-1: "\eaef";
$isax-messenger: "\eaf2";
$isax-microphone: "\eaf3";
$isax-microphone-2: "\eaf4";
$isax-microphone-slash: "\eaf5";
$isax-microphone-slash-1: "\eaf6";
$isax-microscope: "\eaf7";
$isax-milk: "\eaf8";
$isax-mini-music-sqaure: "\eaf9";
$isax-minus: "\eafa";
$isax-minus-cirlce: "\eafb";
$isax-minus-square: "\eafc";
$isax-mirror: "\eafd";
$isax-mirroring-screen: "\eafe";
$isax-mobile: "\eaff";
$isax-mobile-programming: "\eb00";
$isax-monero-xmr: "\eb01";
$isax-money: "\eb02";
$isax-money-2: "\eb03";
$isax-money-3: "\eb04";
$isax-money-4: "\eb05";
$isax-money-add: "\eb06";
$isax-money-change: "\eb07";
$isax-money-forbidden: "\eb08";
$isax-money-recive: "\eb09";
$isax-money-remove: "\eb0a";
$isax-moneys: "\eb0b";
$isax-money-send: "\eb0c";
$isax-money-tick: "\eb0d";
$isax-money-time: "\eb0e";
$isax-monitor: "\eb0f";
$isax-monitor-mobbile: "\eb10";
$isax-monitor-recorder: "\eb11";
$isax-moon: "\eb12";
$isax-more: "\eb13";
$isax-more-2: "\eb14";
$isax-more-circle: "\eb15";
$isax-more-square: "\eb16";
$isax-mouse: "\eb17";
$isax-mouse-1: "\eb18";
$isax-mouse-circle: "\eb19";
$isax-mouse-square: "\eb1a";
$isax-music: "\eb1b";
$isax-music-circle: "\eb1c";
$isax-music-dashboard: "\eb1d";
$isax-music-filter: "\eb1e";
$isax-music-library-2: "\eb1f";
$isax-musicnote: "\eb20";
$isax-music-play: "\eb21";
$isax-music-playlist: "\eb22";
$isax-music-square: "\eb23";
$isax-music-square-add: "\eb24";
$isax-music-square-remove: "\eb25";
$isax-music-square-search: "\eb26";
$isax-nebulas-nas: "\eb27";
$isax-nem-xem: "\eb28";
$isax-nexo-nexo: "\eb29";
$isax-next: "\eb2a";
$isax-note: "\eb2b";
$isax-note-1: "\eb2c";
$isax-note-2: "\eb2d";
$isax-note-21: "\eb2e";
$isax-note-add: "\eb2f";
$isax-note-favorite: "\eb30";
$isax-note-remove: "\eb31";
$isax-note-square: "\eb32";
$isax-note-text: "\eb33";
$isax-notification: "\eb34";
$isax-notification-1: "\eb35";
$isax-notification-circle: "\eb37";
$isax-notification-favorite: "\eb38";
$isax-notification-status: "\eb39";
$isax-ocean-protocol-ocean: "\eb3a";
$isax-okb-okb: "\eb3b";
$isax-omega-circle: "\eb3c";
$isax-omega-circle-1: "\eb3d";
$isax-omega-square: "\eb3e";
$isax-omega-square-1: "\eb3f";
$isax-ontology-ont: "\eb40";
$isax-paintbucket: "\eb41";
$isax-paperclip: "\eb42";
$isax-paperclip-2: "\eb43";
$isax-password-check: "\eb44";
$isax-path: "\eb45";
$isax-path-2: "\eb46";
$isax-path-square: "\eb47";
$isax-pause: "\eb48";
$isax-pause-circle: "\eb49";
$isax-paypal: "\eb4a";
$isax-pen-add: "\eb4b";
$isax-pen-add-1: "\eb4c";
$isax-pen-close: "\eb4d";
$isax-pen-close-1: "\eb4e";
$isax-pen-remove: "\eb4f";
$isax-pen-remove-1: "\eb50";
$isax-pen-tool: "\eb51";
$isax-pen-tool-1: "\eb52";
$isax-pen-tool-2: "\eb53";
$isax-pen-tool-2-1: "\eb54";
$isax-people: "\eb55";
$isax-percentage-square: "\eb56";
$isax-personalcard: "\eb57";
$isax-pharagraphspacing: "\eb59";
$isax-photoshop: "\eb5a";
$isax-picture-frame: "\eb5b";
$isax-play-add: "\eb5d";
$isax-play-circle: "\eb5e";
$isax-play-cricle: "\eb5f";
$isax-play-remove: "\eb60";
$isax-polkadot-dot: "\eb61";
$isax-polygon-matic: "\eb62";
$isax-polyswarm-nct: "\eb63";
$isax-presention-chart: "\eb64";
$isax-previous: "\eb65";
$isax-printer: "\eb66";
$isax-printer-slash: "\eb67";
$isax-profile: "\eb68";
$isax-profile-2user: "\eb69";
$isax-profile-add: "\eb6a";
$isax-profile-circle: "\eb6b";
$isax-profile-delete: "\eb6c";
$isax-profile-remove: "\eb6d";
$isax-profile-tick: "\eb6e";
$isax-programming-arrow: "\eb6f";
$isax-programming-arrows: "\eb70";
$isax-python: "\eb71";
$isax-quant-qnt: "\eb72";
$isax-quote-down: "\eb73";
$isax-quote-down-circle: "\eb74";
$isax-quote-down-square: "\eb75";
$isax-quote-up: "\eb76";
$isax-quote-up-circle: "\eb77";
$isax-quote-up-square: "\eb78";
$isax-radar: "\eb79";
$isax-radar-1: "\eb7a";
$isax-radar-2: "\eb7b";
$isax-radio: "\eb7c";
$isax-ram: "\eb7d";
$isax-ram-2: "\eb7e";
$isax-ranking: "\eb7f";
$isax-ranking-1: "\eb80";
$isax-receipt: "\eb81";
$isax-receipt-1: "\eb82";
$isax-receipt-2: "\eb83";
$isax-receipt-2-1: "\eb84";
$isax-receipt-add: "\eb85";
$isax-receipt-discount: "\eb86";
$isax-receipt-disscount: "\eb87";
$isax-receipt-edit: "\eb88";
$isax-receipt-item: "\eb89";
$isax-receipt-minus: "\eb8a";
$isax-receipt-search: "\eb8b";
$isax-receipt-square: "\eb8c";
$isax-receipt-text: "\eb8d";
$isax-received: "\eb8e";
$isax-receive-square: "\eb8f";
$isax-receive-square-2: "\eb90";
$isax-record: "\eb91";
$isax-record-circle: "\eb92";
$isax-recovery-convert: "\eb93";
$isax-redo: "\eb94";
$isax-refresh: "\eb95";
$isax-refresh-2: "\eb96";
$isax-refresh-circle: "\eb97";
$isax-refresh-left-square: "\eb98";
$isax-refresh-right-square: "\eb99";
$isax-refresh-square-2: "\eb9a";
$isax-repeat: "\eb9b";
$isax-repeat-circle: "\eb9c";
$isax-repeate-music: "\eb9d";
$isax-repeate-one: "\eb9e";
$isax-reserve: "\eb9f";
$isax-rotate-left: "\eba0";
$isax-rotate-left-1: "\eba1";
$isax-rotate-right: "\eba2";
$isax-rotate-right-1: "\eba3";
$isax-route-square: "\eba4";
$isax-routing: "\eba5";
$isax-routing-2: "\eba6";
$isax-row-horizontal: "\eba7";
$isax-row-vertical: "\eba8";
$isax-rulerpen: "\eba9";
$isax-ruler: "\ebaa";
$isax-safe-home: "\ebab";
$isax-sagittarius: "\ebac";
$isax-save-2: "\ebad";
$isax-save-add: "\ebae";
$isax-save-minus: "\ebaf";
$isax-save-remove: "\ebb0";
$isax-scan: "\ebb1";
$isax-scan-barcode: "\ebb2";
$isax-scanner: "\ebb3";
$isax-scanning: "\ebb4";
$isax-scissor: "\ebb5";
$isax-scissor-1: "\ebb6";
$isax-scissor-2: "\ebb7";
$isax-screenmirroring: "\ebb8";
$isax-scroll: "\ebb9";
$isax-search-favorite: "\ebba";
$isax-search-favorite-1: "\ebbb";
$isax-search-normal: "\ebbc";
$isax-search-normal-1: "\ebbd";
$isax-search-status: "\ebbe";
$isax-search-status-1: "\ebbf";
$isax-search-zoom-in: "\ebc0";
$isax-search-zoom-in-1: "\ebc1";
$isax-search-zoom-out: "\ebc2";
$isax-search-zoom-out-1: "\ebc3";
$isax-security: "\ebc4";
$isax-security-card: "\ebc5";
$isax-security-safe: "\ebc6";
$isax-security-time: "\ebc7";
$isax-send: "\ebc9";
$isax-send-1: "\ebca";
$isax-send-2: "\ebcb";
$isax-send-sqaure-2: "\ebcc";
$isax-send-square: "\ebcd";
$isax-setting: "\ebce";
$isax-setting-2: "\ebcf";
$isax-setting-3: "\ebd0";
$isax-setting-4: "\ebd1";
$isax-setting-5: "\ebd2";
$isax-settings: "\ebd3";
$isax-shapes: "\ebd4";
$isax-shapes-1: "\ebd5";
$isax-shapes-2: "\ebd6";
$isax-share: "\ebd7";
$isax-shield: "\ebd8";
$isax-shield-cross: "\ebd9";
$isax-shield-search: "\ebda";
$isax-shield-security: "\ebdb";
$isax-shield-slash: "\ebdc";
$isax-shield-tick: "\ebdd";
$isax-ship: "\ebde";
$isax-shop: "\ebdf";
$isax-shop-add: "\ebe0";
$isax-shopping-bag: "\ebe1";
$isax-shopping-cart: "\ebe2";
$isax-shop-remove: "\ebe3";
$isax-siacoin-sc: "\ebe4";
$isax-sidebar-bottom: "\ebe5";
$isax-sidebar-left: "\ebe6";
$isax-sidebar-right: "\ebe7";
$isax-sidebar-top: "\ebe8";
$isax-signpost: "\ebe9";
$isax-simcard: "\ebea";
$isax-simcard-1: "\ebeb";
$isax-simcard-2: "\ebec";
$isax-size: "\ebed";
$isax-slack: "\ebee";
$isax-slash: "\ebef";
$isax-slider: "\ebf0";
$isax-slider-horizontal: "\ebf1";
$isax-slider-horizontal-1: "\ebf2";
$isax-slider-vertical: "\ebf3";
$isax-slider-vertical-1: "\ebf4";
$isax-smallcaps: "\ebf5";
$isax-smart-car: "\ebf6";
$isax-smart-home: "\ebf7";
$isax-smileys: "\ebf8";
$isax-sms: "\ebf9";
$isax-sms-edit: "\ebfa";
$isax-sms-notification: "\ebfb";
$isax-sms-search: "\ebfc";
$isax-sms-star: "\ebfd";
$isax-sms-tracking: "\ebfe";
$isax-snapchat: "\ebff";
$isax-solana-sol: "\ec00";
$isax-sort: "\ec01";
$isax-sound: "\ec02";
$isax-speaker: "\ec03";
$isax-speedometer: "\ec04";
$isax-spotify: "\ec05";
$isax-stacks-stx: "\ec06";
$isax-star: "\ec07";
$isax-star-1: "\ec08";
$isax-star-slash: "\ec09";
$isax-status: "\ec0a";
$isax-status-up: "\ec0b";
$isax-stellar-xlm: "\ec0c";
$isax-stickynote: "\ec0e";
$isax-stop: "\ec0f";
$isax-stop-circle: "\ec10";
$isax-story: "\ec11";
$isax-strongbox: "\ec12";
$isax-strongbox-2: "\ec13";
$isax-subtitle: "\ec14";
$isax-sun: "\ec15";
$isax-sun-1: "\ec16";
$isax-sun-fog: "\ec17";
$isax-tag: "\ec18";
$isax-tag-2: "\ec19";
$isax-tag-cross: "\ec1a";
$isax-tag-right: "\ec1b";
$isax-tag-user: "\ec1c";
$isax-task: "\ec1d";
$isax-task-square: "\ec1e";
$isax-teacher: "\ec1f";
$isax-tenx-pay: "\ec20";
$isax-tether-usdt: "\ec21";
$isax-text: "\ec22";
$isax-textalign-center: "\ec23";
$isax-textalign-justifycenter: "\ec24";
$isax-textalign-justifyleft: "\ec25";
$isax-textalign-justifyright: "\ec26";
$isax-textalign-left: "\ec27";
$isax-textalign-right: "\ec28";
$isax-text-block: "\ec29";
$isax-text-bold: "\ec2a";
$isax-text-italic: "\ec2b";
$isax-text-underline: "\ec2c";
$isax-the-graph-grt: "\ec2d";
$isax-theta-theta: "\ec2e";
$isax-thorchain-rune: "\ec2f";
$isax-tick-circle: "\ec30";
$isax-ticket: "\ec31";
$isax-ticket-2: "\ec32";
$isax-ticket-discount: "\ec33";
$isax-ticket-expired: "\ec34";
$isax-ticket-star: "\ec35";
$isax-tick-square: "\ec36";
$isax-timer: "\ec37";
$isax-timer-1: "\ec38";
$isax-timer-pause: "\ec39";
$isax-timer-start: "\ec3a";
$isax-toggle-off: "\ec3b";
$isax-toggle-off-circle: "\ec3c";
$isax-toggle-on: "\ec3d";
$isax-toggle-on-circle: "\ec3e";
$isax-trade: "\ec3f";
$isax-transaction-minus: "\ec40";
$isax-translate: "\ec41";
$isax-trash: "\ec42";
$isax-tree: "\ec43";
$isax-trello: "\ec44";
$isax-trend-down: "\ec45";
$isax-trend-up: "\ec46";
$isax-triangle: "\ec47";
$isax-triangle-1: "\ec48";
$isax-trontron-trx: "\ec49";
$isax-truck-remove: "\ec4a";
$isax-truck-tick: "\ec4b";
$isax-truck-time: "\ec4c";
$isax-trush-square: "\ec4d";
$isax-twitch: "\ec4e";
$isax-ui8: "\ec4f";
$isax-undo: "\ec50";
$isax-unlimited: "\ec51";
$isax-unlock: "\ec52";
$isax-usd-coin-usdc: "\ec53";
$isax-user: "\ec54";
$isax-user-add: "\ec55";
$isax-user-cirlce-add: "\ec56";
$isax-user-edit: "\ec57";
$isax-user-minus: "\ec58";
$isax-user-octagon: "\ec59";
$isax-user-remove: "\ec5a";
$isax-user-search: "\ec5b";
$isax-user-square: "\ec5c";
$isax-user-tag: "\ec5d";
$isax-user-tick: "\ec5e";
$isax-velas-vlx: "\ec5f";
$isax-verify: "\ec60";
$isax-vibe-vibe: "\ec61";
$isax-video: "\ec62";
$isax-video-add: "\ec63";
$isax-video-circle: "\ec64";
$isax-video-horizontal: "\ec65";
$isax-video-play: "\ec66";
$isax-video-remove: "\ec67";
$isax-video-slash: "\ec68";
$isax-video-square: "\ec69";
$isax-video-tick: "\ec6a";
$isax-video-time: "\ec6b";
$isax-video-vertical: "\ec6c";
$isax-volume-cross: "\ec6d";
$isax-volume-high: "\ec6e";
$isax-volume-low: "\ec6f";
$isax-volume-low-1: "\ec70";
$isax-volume-mute: "\ec71";
$isax-volume-slash: "\ec72";
$isax-volume-up: "\ec73";
$isax-vuesax: "\ec74";
$isax-wallet: "\ec75";
$isax-wallet-1: "\ec76";
$isax-wallet-2: "\ec77";
$isax-wallet-3: "\ec78";
$isax-wallet-add: "\ec79";
$isax-wallet-add-1: "\ec7a";
$isax-wallet-check: "\ec7b";
$isax-wallet-minus: "\ec7c";
$isax-wallet-money: "\ec7d";
$isax-wallet-remove: "\ec7e";
$isax-wallet-search: "\ec7f";
$isax-wanchain-wan: "\ec80";
$isax-wanchain-wan-1: "\ec81";
$isax-warning-2: "\ec82";
$isax-watch: "\ec83";
$isax-watch-status: "\ec84";
$isax-weight: "\ec85";
$isax-weight-1: "\ec86";
$isax-whatsapp: "\ec87";
$isax-wifi: "\ec88";
$isax-wifi-square: "\ec89";
$isax-wind: "\ec8a";
$isax-wind-2: "\ec8b";
$isax-windows: "\ec8c";
$isax-wing-wing: "\ec8d";
$isax-woman: "\ec8e";
$isax-xd: "\ec8f";
$isax-xiaomi: "\ec90";
$isax-xrp-xrp: "\ec91";
$isax-youtube: "\ec92";
$isax-zel-zel: "\ec93";
$isax-zoom: "\ec94";
$isax-dcube: "\ec95";
$isax-d-cube-scan: "\ec96";
$isax-d-rotate: "\ec97";
$isax-d-square: "\ec98";
$isax-full: "\ec99";
$isax-square: "\ec9a";

