/* Define Cairo Font Face with Multiple Weights */

/* Regular */
@font-face {
  font-family: 'Cairo';
  src: url('../../../fonts/ar/Cairo/Cairo-VariableFont_slnt\,wght.ttf') format('truetype');

}




/* Usage Example */
body {
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: "Arciform";
  src: url("../../../fonts/en/Arciform.otf");
}
$font-family-monospace: 'Arciform', sans-serif;

$font-family-sans-serif: 'cairo',
sans-serif;
