/*
Template Name: Magdy elsayyad. - Admin & Dashboard Template
Author: Sahely 

Website: https://www.linkedin.com/in/magdy-elsayyad-14a086193
Contact: magdyelsayyad229@gmail.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";