/*
Template Name: Magdy theme.
Author: Magdy Elsayyad

Website: https://www.linkedin.com/in/magdy-elsayyad-14a086193/
Contact: magdy.elsayyad229@gmail.com
File: Custom Bootstrap Css File
*/
//Fonts



//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./grid-breakpoints";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/variables-dark";
@import "variables";
@import "custom/fonts/fonts-rtl";

@import "./node_modules/bootstrap/scss/maps";
@import "./node_modules/bootstrap/scss/utilities";
@import "custom/rtl/utilities-rtl";
@import "custom-utils";
@import "./node_modules/bootstrap/scss/mixins.scss";
// @import "bootstrap/scss/utilities/api";

@import "./node_modules/bootstrap/scss/bootstrap";

@import 'custom-functions';




@import "custom/components/reboot";
@import "custom/components/backgrounds";
@import "custom/components/badge";
@import "custom/components/buttons";
@import "custom/components/breadcrumb";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/nav";
@import "custom/components/table";
@import "custom/components/pagination";
@import "custom/components/progress";



// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "node_modules/swiper/swiper.scss";
@import "custom/components/waves";
@import "custom/components/timeline";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
// @import "custom/plugins/dragula";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
// @import "custom/plugins/rating";
// @import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
// @import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";
// @import "custom/plugins/chartist";

// @import "custom/plugins/echarts";

@import "custom/plugins/owl-carousel";
// @import "custom/plugins/lightbox";

// Pages
@import "custom/pages/authentication";
// @import "custom/pages/ecommerce";
// @import "custom/pages/email";
// @import "custom/pages/file-manager";
// @import "custom/pages/chat";
// @import "custom/pages/projects";
// @import "custom/pages/contacts";
// @import "custom/pages/coming-soon";
// @import "custom/pages/timeline";
@import "custom/pages/extras-pages";

@import "./custom";

// rtl
@import "custom/rtl/general-rtl";
@import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
@import "custom/rtl/structure-rtl";
@import "custom/rtl/plugins-rtl";
@import "custom/rtl/components-rtl";
@import "custom/rtl/pages-rtl";

.bx-chevron-right,.bx-chevron-left{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}



.custom-swiper-btn.swiper-button-prev {
    background: linear-gradient(270deg, #F5F5F5 50%, rgba(245, 245, 245, 0.00) 100%) !important;
}

.custom-swiper-btn.swiper-button-next {
    background: linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, #F5F5F5 51%) !important;
}
