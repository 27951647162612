@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @if $color == "light" {
        @include button-variant(
          $value,
          $value,
          $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
          $hover-border: shade-color($value, $btn-hover-border-shade-amount),
          $active-background: shade-color($value, $btn-active-bg-shade-amount),
          $active-border: shade-color($value, $btn-active-border-shade-amount)
        );
      } @else if $color == "dark" {
        @include button-variant(
          $value,
          $value,
          $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
          $hover-border: tint-color($value, $btn-hover-border-tint-amount),
          $active-background: tint-color($value, $btn-active-bg-tint-amount),
          $active-border: tint-color($value, $btn-active-border-tint-amount)
        );
      } @else {
        @include button-variant($value, $value);
      }
    }
  }