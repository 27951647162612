// --------------------------------------------------------------
// Control buttons
// --------------------------------------------------------------

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: $plyr-control-radius;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible; // IE11
  padding: $plyr-control-padding;
  position: relative;
  transition: all 0.3s ease;

  svg {
    display: block;
    fill: currentColor;
    height: $plyr-control-icon-size;
    pointer-events: none;
    width: $plyr-control-icon-size;
  }

  // Default focus
  &:focus {
    outline: 0;
  }

  // Tab focus
  &:focus-visible {
    @include plyr-focus-visible;
  }
}

// Remove any link styling
a.plyr__control {
  text-decoration: none;

  &::after,
  &::before {
    display: none;
  }
}

// Change icons on state change
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}
